import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { setViewTrackList ,setOnPlay, setProject, setPlayerState, setView, setAlbumSelected } from '../../app/playerSlice'

import { AppContext } from '../../AppContext'


export default function AlbumItem({item}) {

    let playerState = useSelector((state)=>{
        return state.player
    })
      const dispatch = useDispatch()
        
    const  {
        _idx,
        name,
        members,
        year,
        img,
        tracks
    } = item
  
    return(
        <div onClick={()=>{ dispatch(setViewTrackList({value:true})); dispatch(setAlbumSelected({value:_idx})); console.log('ok') }}
        className='proyect-link w-100'  >
            <div className='proyect-card animated-gradient'>
                <div className='card-img-container' style={{'backgroundImage': `url("${img}")`}}>
                    
                </div>
                <div className='card-content album-card-content'>
                    <div className='card-info'>
                        <div className='album-info d-flex'>
                            <h2>album</h2>
                            <h2>{tracks.length}{tracks.length === 1 ? ' track' : ' tracks'}</h2>
                        </div>
                        <h2>{name}</h2>
                        <h2>{year}</h2>
                    </div>
                    <FontAwesomeIcon onClick={
                        () => {
                            dispatch(setOnPlay({value:true}))
                            dispatch(setProject({projectIndex:playerState.viewSelected, albumIndex: _idx, trackIndex:0}))
                        
                        }
                    } className='card-icon' icon={faPlayCircle} />
                </div>
            </div>
        </div>
    )
}