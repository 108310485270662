import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { setOnPlay, setProject, setPlayerState, setView } from '../../app/playerSlice'

import { AppContext } from '../../AppContext'


export default function ProyectItem({item}) {

    let playerState = useSelector((state)=>{
        return state.player
    })
      const dispatch = useDispatch()
        
    const  {
        _idx,
        name,
        members,
        type,
        year,
        img,
        _id,
        endYear, 
    } = item
  
    return(
        <Link onClick={

            () => {
                console.log(_idx)
                dispatch(setView({value:_idx}))
      
             
            }
        }
        className='proyect-link' to={{
                pathname:`/proyectos/${_idx}`
        }} >
            <div className='proyect-card animated-gradient'>
                <div className='card-img-container' style={{'backgroundImage': `url("${img.perfil}")`}}>
                    
                </div>
                <div className='card-content'>
                    <div className='card-info'>
                        <h2>{type}</h2>
                        <h2>{name}</h2>
                        {
                            endYear ? 
                            <h2>{`${year}/${endYear}`}</h2> :
                            <h2>{`${year}`}</h2>
                        }
                    </div>
                    
                </div>
            </div>
        </Link>
    )
}