import React, {useState, useEffect, useContext} from 'react' 

import '../gallery/gallery.css'
import Gallery from '../gallery/Gallery'
import Viewer from '../viewer/Viewer'

import { GalleryContext } from '../galleryContext/GalleryContext'
import { useSelector, useDispatch } from 'react-redux'


export default function ProjectGallery() {

    const dispatch = useDispatch()

    const playerState = useSelector((state)=>{
        return state.player
    })


    const [navState, setNavState, viewer, setViewer, galleryView, setGalleryView, galleryList, setGalleryList] = useContext(GalleryContext)

    let project = playerState.projects.find((item)=>{
        return playerState.viewSelected === item._idx
    })
    useEffect(()=>{

      
        console.log(project.media.pictures)

        if(project.media.pictures.length!==0){
            setGalleryList(project.media.pictures)
            setGalleryView('fotos')
        }
        if(project.media.videos.length!==0){
            setGalleryView('videos')
        }
        console.log(playerState)
        console.log(project)
    },[playerState])

    const VideoGalery = () => {
        return(
            <div className='video-conteiner'>
                {

                    project.media.videos.map((video) => {
                        return(
                            <div className='video-item'>
                                <h2>{video.title}</h2>
                                <iframe width="100%" height="100%" src={project.media.videos[0].url}  ></iframe>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    
    return(
        
            <div className='galery-view'>
                <div className='galery-nav'>
                    <ul>
                        <li>
                            {
                                project && project.media.pictures.length !== 0 ?
                                <button onClick={()=>{ setGalleryView('fotos') }} className={galleryView === 'fotos' ? 'active' : ''}>
                                    Fotos
                                </button> : ''
                            }
                        </li>
                        <li>
                            {
                                project && project.media.videos.length !== 0 ?
                                <button onClick={()=>{ setGalleryView('videos') }} className={galleryView === 'videos' ? 'active' : ''}>
                                    Videos
                                </button> : ''
                            }
                        </li>
                    </ul>
                </div>
                
                {
                    galleryView === 'fotos' && project &&  project.media.pictures.length !== 0  ?
                     <Gallery/> : ''
                }
                {

                    galleryView === 'videos' ? <VideoGalery/>
                     : '' 
                }
                {
                    viewer === true ? <Viewer /> : ''
                }
            </div>
    )
} 